import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import api from "../../api/api";
import Loader from "../../components/Loader";

import ActivityLogTable from "../../components/tables/ActivityLogTable";

function ActivityLog({ data, handleShowLogs }) {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = useSelector((state) => state.authReducer);

  useEffect(() => {
    api
      .get(
        `api/ActivityLog/${data?.applicationVersionId}/${data?.schoolId}/List?v=1.0`,
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setLogs(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, []);

  if (error) return <div style={{ color: "red" }}>{error}</div>;
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <ActivityLogTable activityLogs={logs} handleShowLogs={handleShowLogs} />
      )}
    </>
  );
}

export default ActivityLog;
